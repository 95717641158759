import React from "react";
import { Link } from 'gatsby';

import SEO from "../seo";
import Footer from '../footer';
import Contact from '../contact';
import Data from '../data';

import Translate from '../../utils/translate';
import difmLogo from '../../images/logo.png';


export default class extends React.Component {

    scrollTop = () => {

        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    getSetting = (settings, name) => {

        for (let i = 0; i < settings.length; ++i) {
            if (settings[i].name === name) {
                return settings[i].value;
            }
        }
        return null;
    }

    getCookieValue = (name) => {

        if (typeof document !== 'undefined' && document.cookie) {
            var value = "; " + document.cookie;
            var parts = value.split("; " + name + "=");
            if (parts.length === 2) {
                return parts.pop().split(";").shift();
            }    
        }
        return null;
    }

    getLanguage = () => {

        const prefs = this.getCookieValue('prefs');
        const langauge = prefs ? prefs.split(',')[1] : 'en_US';
        return langauge;
    } 

    render() {

        const { settings, countries, languages, country, channel, translations, blog } = this.props.pageContext;
        const language = this.getLanguage();
        const t = Translate(translations, language);

        const lang = language.substring(0, 2);

        const description = blog ? blog.title : this.getSetting(settings, `seo_description_${lang}`);
        const title = blog ? blog.title : 'Blog'

        return (
            <>
                <SEO lang={language} description={description} title={title} keywords={[`do it for me`, `services`, `lawn mower`, `installation`]}  t={t} />
                <div className="blog">
                    <div className="container header">
                        <div className="row align-item-center justify-content-between">
                            <div className="col-6 col-lg-3 d-flex align-items-center">
                                <Link to="/blog">
                                    <div className="title d-flex align-items-center">
                                        Blog
                                        <img alt="DIFM Blog" src={difmLogo} />
                                    </div>
                                </Link>
                            </div>
                            <div className="col-6 col-lg-3 difm d-flex align-items-center justify-content-end difm-home">
                                <Link to={`/${language.toLowerCase()}/${country.toLowerCase()}/`}>
                                    <div className="difm d-flex align-items-center justify-content-end">
                                        {t('back to')} doitforme.services
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {
                        React.Children.map(this.props.children, (child) => child)
                    }
                </div>
                <div>
                    <div className="container desktop">
                        <div className="bookServiceNowDiv">
                            <span>
                                {t('CTA Title')}
                            </span>
                            <Link to={`/${language.toLowerCase()}/${country.toLowerCase()}/search`}>
                                <button type="button" className="btn btn-primary bookServiceNowDivButton" id="bookServiceNowDivButton">
                                    {t('CTA Button')}
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="mobile">
                        <div className="bookServiceNowDiv">
                            <span>
                                {t('CTA Title')}
                            </span>
                            <Link to={`/${language.toLowerCase()}/${country.toLowerCase()}/search`}>
                                <button type="button" className="btn btn-primary bookServiceNowDivButton" id="bookServiceNowDivButton">
                                    {t('CTA Button')}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <Contact country={country} language={language} channel={channel} t={t} />
                <Data country={country} language={language} channel={channel} t={t} />
                <Footer countries={countries} languages={languages} location={this.props.location} country={country} language={language} channel={channel} t={t} />
                <div className="toTop d-flex align-items-center justify-content-center" onClick={this.scrollTop}>
                    <i className="la la-angle-up"></i>
                </div>
            </>
        )
    }
}

import React from 'react';
import { Link } from 'gatsby';
import BlogPage from '../blogpage';

export default class extends React.Component {

    formatDate = (date) => {

        const d = new Date(date);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
        return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
    }

    render() {

        let { blogs } = this.props.pageContext;

        blogs = blogs || [];

        return (
            <BlogPage {...this.props}>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-lg-7">
                            {
                                blogs.map((featuredBlog) => (
                                    <div key={featuredBlog.id} className="card">
                                        <div className="article-summary">
                                            <div className="img">
                                                <Link to={`/blog/${featuredBlog.url}`}>
                                                    <img alt={featuredBlog.title} src={featuredBlog.img} />
                                                </Link>
                                            </div>
                                            <div className="title">
                                                <Link to={`/blog/${featuredBlog.url}`}>
                                                    {featuredBlog.title}
                                                </Link>
                                            </div>
                                            <div className="meta d-flex">
                                                <div className="when">{this.formatDate(featuredBlog.date)}</div>
                                                <div className="by">by</div>
                                                <div className="author">{featuredBlog.author}</div>
                                            </div>
                                            <div className="summary" dangerouslySetInnerHTML={{ __html: featuredBlog.summary}}>
                                            </div>
                                        </div> 
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </BlogPage>
        )
    }
}
